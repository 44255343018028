@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "ADELIA";
    src: url("../public/assets/fonts/ADELIA.otf");
}

@font-face {
    font-family: "Averta-Black";
    src: url("../public/assets/fonts/averta/Averta_Black.otf");
}

@font-face {
    font-family: "Averta";
    src: url("../public/assets/fonts/averta/Averta.otf");
}

@font-face {
    font-family: "Averta-Bold";
    src: url("../public/assets/fonts/averta/Averta_Bold.otf");
}

@font-face {
    font-family: "Averta-Semibold";
    src: url("../public/assets/fonts/averta/Averta_Semibold.otf");
}

@layer base {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #190833 #f2f2f2;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #f2f2f2;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #190833;
    border-radius: 10px;
    border: 3px solid #f2f2f2;
  }
